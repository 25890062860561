body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.MuiAutocomplete-option[aria-selected="true"] {
  background-color: rgba(0, 0, 0, 0) !important;
}
.MuiAutocomplete-option[data-focus="true"] {
  background-color: rgba(0, 0, 0, 0.04) !important;
}
.MuiDrawer-root .MuiListItemText-root span{
  font-family: 'Open Sans', sans-serif;
  line-height: 1.25rem;
  font-size: .825rem;
  letter-spacing: .01em;
  font-weight: 600;
  color: #5f6368;
}
.loading-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -40px 0 0 -40px;
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #1571bc;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.invoice-box {
  margin: auto;
  padding: 0;
  font-size: 10px;
  line-height: 24px;
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}
.client, .seller, .invoice-data {
  float:left;
  font-size: 14px;
  line-height: 16px;
  margin-bottom:20px;
}
.client {
  border-radius: 10px;
  border: 1px solid rgb(0, 0, 0);
  width: 45%;
  padding:20px;
  text-align: left;
}
.seller-logo {
  width:50%;
  float:left;
  font-size: 26px;
}
.seller {
  float:left;
  width: 50%;
  text-align: right;
  font-size:12px;
}
.invoice-data {
  float:left;
  width: 45%;
  text-align: left;
  padding-left: 20px;
  padding-top: 5px;
}
.invoice-box {
  padding: 20px;
}
.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}
.invoice-box table .heading td {
  vertical-align: middle;
}
.invoice-box table tr.top table td {
  padding-bottom: 20px;
}
.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}
.invoice-box table tr.heading td {
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  line-height: 14px;
}
.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.item {
  font-size: 12px;
  line-height: 13px;
}
.item td {
  padding: 8px;
}
.rb {
  width: 20px;
}
.sifra {
  width: 50px;
}
.naziv {
  width: 100%;
}
.text-right {
  text-align: right;
}
.invoice-title {
  font-size: 20px;
  font-weight: bold;
  line-height:26px;
}
.invoice-number {
  font-size: 14px;
  font-weight: bold;
  line-height:20px;
}
.invoice-box table tr.information table td {
  padding-bottom: 40px;
}
tr.heading td {
  border-top: 1px solid rgb(0, 0, 0) !important;
  border-bottom: 1px solid rgb(0, 0, 0) !important;
}
.heading td {
  padding: 5px;
}
.invoice-box table tr.details td {
  padding-bottom: 20px;
}
.invoice-box table tr.item td {
  border-bottom: 1px solid #eee !important;
}
.invoice-box table tr.item.last td {
  border-bottom: none;
}
.invoice-box tr.item:last-child td {
  border-bottom:1px solid black !important;
}
.footer {
  float: left;
  margin-top: 40px;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
}
.rjsf .MuiButton-root {
  display: none;
}
.jsx-parser {
  float: left;
  width: 100%;
  text-align: left;
  white-space: pre-wrap;
}
.jsx-parser p {
  text-align: left;
}
div.logo-header {
  vertical-align: top;
  display: inline-block;
  text-align: center;
}
div.logo-header img {
  height: 20px;
  margin-right: 30px;
}
div.logo-header .version {
  display: block;
  text-align: left;
  font-size: 10px;
}
@media print {
  .heading {
    font-size: 10px;
    line-height: 11px;
    font-weight: bold;
    border-top: 1px solid black;
  }
  tr.heading {
    border: 1px solid rgb(131, 92, 92) !important;
    background-color: rgba(241, 241, 241, 0.7) !important;
    border-top: 0 !important;
  }
  .rb {
    width: 20px;
  }
  .sifra {
    width: 50px;
  }
  .naziv {
    width: 50%;
  }
}